import React from 'react';

export default function Navbar(){
    return(
        <div className='h-16 bg-white flex items-center px-6'>
            <div className='flex justify-between items-start w-screen'>
                <div className='font-bold text-3xl hover:text-themeBlue transition ease-in duration-700'>TheCodingGal</div>            
                {/* <div className=''>Test</div> */}
            </div>

        </div>
    );
}