import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import landingVideo from "../images/mac-video.mp4"
import { Parallax, ParallaxProvider } from "react-scroll-parallax"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons"
import FadeInSection from "../components/fadeinsection"

import Navbar from "../components/navbar"

import MacBook from "../components/macbook"

const IndexPage = () => (
  // <Layout>
  <ParallaxProvider>
    <div className="bg-white">
      <Navbar />
      <Seo title="Landing" />
      <div className="overflow-hidden md:h-full relative">
        <div className="text-4xl absolute z-10 md:top-1/4 top-1 text-center w-full font-bold text-themeBlack">
          <p className="md:max-w-2xl max-w-sm mx-auto">
            Your dream website is only one consultation away
          </p>
          <div>
            <Link to="#intro">
              <FontAwesomeIcon
                icon={faAngleDoubleDown}
                className="animate-bounce hover:text-themeBlue transition delay-150 duration-300 ease-in-out"
              />
            </Link>
          </div>
        </div>

        <Parallax y={[-10, 10]}>
          <video
            id="background-video"
            autoPlay
            loop
            muted
            className="w-screen relative animate-fade-in"
          >
            <source src={landingVideo} type="video/mp4" />
            Your browser does not support mp4
          </video>
        </Parallax>
      </div>
      <div
        id="intro"
        className="p-10 text-center bg-themeBlue text-2xl text-themeBlack"
      >
        Grow your business online and develop your online reputation -{" "}
        <span className="font-semibold">we've got you covered</span>
      </div>
      <FadeInSection>
        <div className="flex-none md:flex md:px-20 px-10 py-10">
          <div className="right flex-auto md:pr-20 text-center relative">
            <div className="relative">
              <StaticImage
                style={{ width: "200px" }}
                src="../images/tcg.jpg"
                className="animate-slow-bounce rounded-full z-10 hover:scale-50"
                alt="The Coding Gal Image"
              />

              <svg
                className="animate-slow-bounce absolute opacity-60 z-0"
                style={{ height: "200px", top: "100px" }}
              >
                <circle cx="100" cy="100" r="100" fill="RGB(87, 220, 232)" />
                Sorry your browser does not support SVG
              </svg>
            </div>
          </div>
          <div className="left flex-auto md:pt-10 pt-0 z-20">
            <div className="text-4xl">Hello, I'm </div>
            <Parallax x={[-10, 10]}>
              <h1>Katherine</h1>
            </Parallax>
            <p>I'm the founder of TheCodingGal and have worked with clients like Amazon, Boeing, and Honda.</p>
            <p>
              At TheCodingGal we strive to understand our clients and deliver to
              their needs. We specialize in website development, manufacturing
              software consulting and development, and smart
              contracts/blockchain coding.
            </p>
          </div>
        </div>
      </FadeInSection>
      <div className="bg-themeBlue h-screen pt-10">
        {/* <div className="grid grid-cols-3 gap-4 mx-20">
          <div className="bg-themeWhite h-24 relative">
            <div className="absolute bottom-0">Test</div>
          </div>
          <div className="bg-themeWhite relative">
            <div className="absolute bottom-0">Test</div>
          </div>
          <div className="bg-themeWhite relative">
            <div className="absolute bottom-0">Test</div>
          </div>
        </div> */}
      </div>
      {/* <MacBook />
      */}
    </div>
  </ParallaxProvider>
)

export default IndexPage
